import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import ApiService from '../../../utils/ApiService'
import { useParams } from 'react-router-dom'

function UpdateMailTemplate() {
    let {id}=useParams();
    const [name, setName] = useState("")
    const [identifier, setIdentifier] = useState("")
    const [body, setBody] = useState("")
    const [css, setCss] = useState("")
    const [subject, setSubject] = useState("")
    function onSubmitHandler() {
        let formdata = new FormData();
        if (name) {
            formdata["name"]=name
        }
        if (identifier) {
            formdata["identifier"]=identifier
        }
        if (body) {
            formdata["body"]=body

        }
        if (css) {
            formdata["css"]=css

        }
        if (subject) {
            formdata["subject"]=subject
        }
        ApiService.put('appList', id,formdata).then(res => {
            let data = res.data.data
            console.debug("submittedData", data)
        }).catch((error) => {
            console.error(error);
        })
    }
    function onChangeHandler(e) {
        if (e.target.name == "name") {
            setName(e.target.value)
        }
        if (e.target.name == "identifier") {
            setIdentifier(e.target.value)
        }
        if (e.target.name == "body") {
            setBody(e.target.value)
        }
        if (e.target.name == "css") {
            setCss(e.target.value)
        }
        if (e.target.name == "subject") {
            setSubject(e.target.value)
        }
    }
    return (
        <Box >
            <Box sx={{ justifyContent: "space-between", display: "flex  ", backgroundColor: "light", p: 3 }}>
                <Box>
                    <Typography variant='h5'>Update Mail Template</Typography>
                </Box>
                <Box sx={{ float: "end" }}>
                    <Button sx={{ mx: 1 }} variant="text">Cancel</Button>
                    <Button sx={{ mx: 1 }} variant="outlined">Reset</Button>
                    <Button sx={{ mx: 1 }} variant="contained" onClick={onSubmitHandler}>Save</Button>
                </Box>
            </Box>
            <Paper sx={{ p: 4, mt: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField id="outlined-basic" name="name" label="Name" variant="outlined" sx={{ width: "100%" }} onChange={onChangeHandler} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="outlined-basic" name="identifier" label="Identifier" variant="outlined" sx={{ width: "100%" }} onChange={onChangeHandler} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="outlined-basic" name="subject" label="Subject" variant="outlined" sx={{ width: "100%" }} onChange={onChangeHandler} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="outlined-multiline-static"
                            label="Body"
                            multiline
                            rows={4}
                            name="body"
                            sx={{width:"100%"}}
                            onChange={onChangeHandler}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="outlined-multiline-static"
                            label="Css"
                            multiline
                            rows={4}
                            name="css"
                            sx={{width:"100%"}}
                            onChange={onChangeHandler}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    )
}

export default UpdateMailTemplate
