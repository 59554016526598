import React from 'react'

function MenuBar() {
  return (
    <div>
      menu
    </div>
  )
}

export default MenuBar
