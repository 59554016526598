import { createSlice } from "@reduxjs/toolkit";

export const recentTagsData = createSlice({
    name: "recentTag",
    initialState: {
        recentTags: [],
    },
    reducers: {
        setRecentTags: (state, action) => {
            state.recentTags = action.payload;
        },

    }
});

export const { setRecentTags } = recentTagsData.actions;

export default recentTagsData.reducer;
