import React, { useState, useEffect, useRef } from 'react'
import { Box, Card, Grid } from '@mui/material'
import { useDispatch } from 'react-redux';
import { openDialog } from "../../../features/dialogBox/dialogBoxSlice";
import { openDrawer } from "../../../features/drawers/drawerSlice";
import DashboardHeading from './../DashboardHeading';
import MyContext from './../Mycontext';
import TransitionsSnackbar from './../Constantfile/Snackbar';
import MailTemplateTableView from './MailTemplateTableView';
import TableTools from '../TableTools';
import checkboxHelper from '../checkboxHelper';

const MailTemplate = () => {
    const dispatch = useDispatch();
    const openDialogPublish = (id) => {
        dispatch(openDialog(id));
    }
    const [showCode, setShowCode] = useState(false);
    const [showGridView, setShowGridView] = useState(true);
    const [showTreeView, setShowTreeView] = useState(true);
    const [selectedItems, setSelectedItems] = useState(new Set([]));
    const [selectedId, setSelectedId] = useState({ id: '0', value: "", name: "", type: "", delete: "soft" });
    const [snackBar, setSnackBar] = useState({ dataLoad: false, message: "" });
    const [editFlag, setEditFlag] = useState({ flag: false, value: "" })
    const [dashboardList, setDashboardList] = useState([]);
    const [fileCount, setFileCount] = useState(0);
    const [widthHeader, setWidthHeader] = useState("100%");
    const headerRef = useRef();
    const element = document.querySelector('#headerWidthListner');
    const [headerCheckStatus, setHeaderCheckStatus] = useState(false);
    const [checkedData, setCheckedData] = useState();
    const isRecentFiles = true;
    const [checkboxGridHelper, setCheckboxGridHelper] = useState(
        checkboxHelper()
    );
    const openDrawerPublish = (id) => {
        if (id == "editFile") {
            setEditFlag({ flag: false, value: "edit" })
        } else {
            setEditFlag({ flag: true, value: "create" })
        }
        setAnchorEl(null);
        dispatch(openDrawer(id));
    }
    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            for (const entry of entries) {
                const { width, height } = entry.target.getBoundingClientRect();
                setWidthHeader((parseInt(width) - 10) + 'px');
            }
        });
        observer.observe(headerRef.current);
        return () => {
            observer.disconnect();
        };
    }, [element]);
    const handleButtonClick = () => {
        setShowCode(!showCode);
    };

    const handleGridViewClick = () => {
        setShowGridView(true);
        setShowTreeView(false);
    };

    const handleTreeViewClick = () => {
        setShowGridView(false);
        setShowTreeView(true);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    //single Select//
    const [lastModified, setLastModified] = React.useState('');

    const handleModifiedChange = (event) => {
        setLastModified(event.target.value);
    };
    const [location, setLocation] = React.useState('');

    const handleLocationChange = (event) => {
        setLocation(event.target.value);
    };
    const [practice, setPractice] = React.useState('');

    const handlePracticeChange = (event) => {
        setPractice(event.target.value);
    };
    const [stage, setStage] = React.useState('');

    const handleStageChange = (event) => {
        setStage(event.target.value);
    };
    const [technology, setTechnology] = React.useState('');

    const handleTechnologyChange = (event) => {
        setStage(event.target.value);
    };

    useEffect(() => {
        document.body.style.overflow = 'auto'
    }, [])

    // const openDrawerPublish = (id) => {
    //     if (id == "editFile") {
    //         setEditFlag({ flag: false, value: "edit" })
    //     } else {
    //         setEditFlag({ flag: true, value: "create" })
    //     }
    //     setAnchorEl(null);
    //     dispatch(openDrawer(id));
    // }
    const methodsAndState = {
        open,
        handleClick,
        anchorEl,
        handleClose,
        openDialogPublish,
        openDrawerPublish,
        handleModifiedChange,
        lastModified,
        handleLocationChange,
        location,
        handlePracticeChange,
        practice,
        handleStageChange,
        stage,
        handleTechnologyChange,
        technology,
        showGridView,
        showTreeView,
        setSelectedItems,
        selectedItems,
        selectedId, setSelectedId, setSnackBar, snackBar,
        setEditFlag, setFileCount,
        editFlag,
        dashboardList,
        setDashboardList,
        // setCategoryList,
        checkboxGridHelper,
        // categoryList,
        showCode, setShowCode, setHeaderCheckStatus, headerCheckStatus,
        checkedData, setCheckedData, isRecentFiles
    };
    return (
        <MyContext.Provider value={methodsAndState}>
            <Box >
                <Card sx={{ mb: 2, minHeight: '83vh' }} id="headerWidthListner" ref={headerRef}>
                    <Box id="sticky" sx={{ paddingBottom: selectedItems.size ? '' : '12px !important', width: widthHeader }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                {selectedItems.size ? "" : <DashboardHeading title={'Mail Templates'} numItems={""} />}
                                <TableTools />

                            </Grid>
                        </Grid>
                    </Box>
                    {/* -------------- GRID AND TREE -------------- */}
                    <Box className="gridTabularView" sx={{ mt: { xl: "2rem", lg: "2rem", md: "2rem", sm: "2rem", xs: selectedItems.size ? "4rem" : '2rem' } }}>
                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <MailTemplateTableView />
                            </Grid>
                        </Grid>
                    </Box>
                </Card>

                {/* <SharedDialog id="deleteDialog">
                    <DeleteDialog />
                </SharedDialog>
                <SharedDialog id="renameDialog">
                    <RenameDialog />
                </SharedDialog> */}
                <TransitionsSnackbar />
            </Box>
        </MyContext.Provider>
    )
}

export default MailTemplate