import { Box, Checkbox, FormControlLabel, FormGroup, ListItemText, MenuItem, MenuList, Paper } from '@mui/material';
import React, { useContext, } from 'react';
import MyContext from './Mycontext';

const TableTools = (props) => {
  const methodsAndState = useContext(MyContext);
  // useEffect(() => {
  //   if (methodsAndState.selectedItems.size && methodsAndState.dashboardList) {
  //     const matchingData = methodsAndState.dashboardList.filter(item => methodsAndState.selectedItems.has(item.id));
  //   }

  // }, [methodsAndState.selectedItems])
  const itemSelected = methodsAndState.selectedItems.size

  console.debug("methodsAndState:TableTool",methodsAndState.showTreeView)
  return (
    <Box>
      {methodsAndState.selectedItems.size ? (
        <Box sx={{ display: "flex", alignItems: "center", flexWrap: { xs: 'wrap', sm: 'wrap' } }}>
          {methodsAndState.showTreeView ?
            <Box>{itemSelected > 1 ? itemSelected + ' Items Selected' : itemSelected + ' Item Selected'}</Box>
            : <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={methodsAndState.checkboxGridHelper.headerCheckboxListener}
                    checked={methodsAndState.headerCheckStatus}
                  />
                }
                label={itemSelected > 1 ? itemSelected + ' Items Selected' : itemSelected + ' Item Selected'}
              />
            </FormGroup>}
          <Paper sx={{ width: 320 }}>
            <MenuList dense>
              <MenuItem>
                <ListItemText inset>Single</ListItemText>
              </MenuItem>
            </MenuList>
          </Paper>
        </Box>
      ) : null}
    </Box>
  );
};

export default TableTools;