function Observable(value) {
     
          this.subscribers = [];
          this.get = function () {
              return value;
          };
  
          this.set = function (newValue) {
              if (value !== newValue) {
                  value = newValue;
                  this.subscribers.forEach(function (subscriber) {
                      subscriber(newValue);
                  });
              }
          };
  
          this.subscribe = function (subscriber) {
              this.subscribers.push(subscriber);
          };
  
          this.unsubscribe = function (subscriber) {
              var index = this.subscribers.indexOf(subscriber);
              if (index !== -1) {
                  this.subscribers.splice(index, 1);
              }
          };
      }

export {Observable};