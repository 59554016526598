import { createSlice } from '@reduxjs/toolkit';

const drawerSlice = createSlice({
  name: 'drawer',
  initialState: {
    isOpen: false,
    sidebarIsOpen: false,
    id: ''
  },
  reducers: {
    openDrawer: (state, actions) => {
      state.isOpen = true;
      state.id = actions.payload;
    },
    closeDrawer: (state, actions) => {
      state.isOpen = false;
      state.id = actions.payload;
    },
    sidebarOpenDrawer: (state, action) => {
      state.sidebarIsOpen = action.payload;
    },
  },
});

export const { openDrawer, closeDrawer, sidebarOpenDrawer } = drawerSlice.actions;

export default drawerSlice.reducer;
