import React from "react";
import Sidebar from "../layouts/sidebar/Sidebar";
import ThemeProvider from "../theme";
import MailTemplate from "../pages/KnowledgeManagement/MailTemplate/MailTemplate"
import CreateMailTemplate from "../pages/KnowledgeManagement/MailTemplate/CreateMailTemplate"
import UpdateMailTemplate from "../pages/KnowledgeManagement/MailTemplate/UpdateMailTemplate"
import ViewMailTemplate from "../pages/KnowledgeManagement/MailTemplate/ViewMailTemplate"
import EventList from "../pages/KnowledgeManagement/Event /EventList";
import EventCategorytList from "../pages/KnowledgeManagement/EventCategory/EvenCategorytList";
import PlaceholderList from "../pages/KnowledgeManagement/Placeholder/PlaceholderList";
import PlaceholderCategoryList from "../pages/KnowledgeManagement/PlaceholderCategory/PlaceholderCategoryList";
import TriggerMailList from "../pages/KnowledgeManagement/TriggerMail/TriggerMailList";
const windowObject = window;

export function PrivateComponent() {
  let response = [
    {
      url: "/",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <MailTemplate />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/create",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            < CreateMailTemplate/>
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/edit/:id",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <UpdateMailTemplate/>
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/previou",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <ViewMailTemplate/>
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/event",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <EventList />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/event-category",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            < EventCategorytList/>
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/placeholder",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            < PlaceholderList/>
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/placeholder-category",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            < PlaceholderCategoryList/>
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/trigger-mail",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            < TriggerMailList/>
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
  ];
  return response;
}
