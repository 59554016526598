import React from 'react';
import parseStyle from 'style-parser';
const EmailViewer = ({ emailTemplate,emailTemplateCss }) => {
  console.debug("emailTemplate",emailTemplate)
  console.debug("emailTemplateCss",emailTemplateCss)
  const replaceClassWithClassName = (htmlContent) => {
    // Use a regular expression to replace 'class' with 'className' globally
    return htmlContent.replace(/class=/g, 'className=');
  };
  const containerStyle = parseStyle(emailTemplateCss);
  const sanitizedHtml = replaceClassWithClassName(emailTemplate.body);
  const combinedHtml = `${sanitizedHtml}<style>${containerStyle}</style>`;
  const emailTemplateCssStatic = {
    backgroundColor: '#ff0000', // Example dynamic style
    fontFamily: 'Arial, sans-serif',
    Heading: {
      color: 'white',
    },
   // Another example dynamic style
    // ... other dynamic styles
  };  // const containerStyle = parseStyle(emailTemplateCss);
  return (
    <div style={emailTemplateCssStatic}>
      {/* <style>{emailTemplateCssStatic}</style> */}
      <div dangerouslySetInnerHTML={{ __html: combinedHtml }}   />
    </div>
  //   <div>
  // //   <style dangerouslySetInnerHTML={{ __html: emailTemplateCss }} />

  // //   <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
  // // </div>
  //   // <div style={emailTemplateCssStatic}>
  //     <style>{containerStyle}</style>
  //   <h2 className='Heading'>{emailTemplate.subject}</h2>
  //   <h2>{emailTemplate.name}</h2>
  //   <p dangerouslySetInnerHTML={{ __html: emailTemplate.body }} />
  // </div>
  );
};

export default EmailViewer;