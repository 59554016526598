import React, { useContext, useEffect, useState } from 'react'
import { Box, Typography, Grid, Skeleton, Button, IconButton } from '@mui/material'
import TableComponent from '../../../features/TableComponent/TableComponent';
import ApiService from '../../../utils/ApiService';
import moment from 'moment';
import MyContext from './../Mycontext';
import Checkbox from '@mui/material/Checkbox';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
// import ImageType from './ImageType';
import InfiniteScroll from 'react-infinite-scroll-component';
import { setPageNo, setScrollFlag } from '../../../features/dashboardData/dashboardData';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MenuBar from './../MenuBar';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#2563EB',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: '#000000',
        fontWeight: '400',
        boxShadow: '-1px 0px 6px #aaa',
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid #2563EB',
        borderRadius: 2,
    },
}));

const PlaceholderCategoryTableView = () => {
    const _ = require("lodash");
    const methodsAndState = useContext(MyContext);
    const [headerCheckStatus, setHeaderCheckStatus] = useState(false);
    const [loading, setLoading] = React.useState(true)
    const [metaData, setMetaData] = useState();
    const dashboardScroll = useSelector((state) => (state.dashboardScroll));
    const [checkedData, setCheckedData] = useState()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isMobileView = window.innerWidth < 933; // Adjust the breakpoint as needed
    const [disablePinBtn, setDisablePinBtn] = useState(false);
    useEffect(function () {
        recentApiCall();
    }, [])  
    const checkBoxHandler = (data) => {
        methodsAndState.checkboxGridHelper.checkboxListener(data.id)
        setCheckedData(data)
        methodsAndState.setSelectedId({ id: data.id, value: "checkbox", type: data.type, name: data.name, delete: "notall" })
    }

    const recentApiCall = () => {
        // if (!userList?.userId) {
        //     return;
        // }
        if (dashboardScroll.pageNo + 1 == 1) {
            setLoading(true)
        }
        let params = {
            limit: 30,
            page: dashboardScroll.pageNo + 1,
            // user: userList?.userId
        }
        ApiService.getMail('event', params).then(res => {
            let data = res.data
            if(data){
                methodsAndState.setDashboardList(data)
                dispatch(setPageNo(res.data.meta.current_page))
                setMetaData(res.data.meta)
            }
            setLoading(false)
            if (data) {
                if (dashboardScroll.scrollFlag) {
                    methodsAndState.setDashboardList([...methodsAndState.dashboardList, ...data.map((d) => formatData(d))])
                    // methodsAndState.setFileCount(methodsAndState.dashboardList.length + data.length)
                } else {
                    methodsAndState.setDashboardList(data.map((d) => formatData(d)))
                    // methodsAndState.setFileCount(data.length)
                }
                console.log({ data: data.map((d) => formatData(d)) })
            }
            methodsAndState.setFileCount(res?.data?.meta?.total)
        }).catch((error) => {
            console.error(error);
            setLoading(false)
            methodsAndState.setSnackBar({ dataLoad: true, message: error.message, severity: "error" })
        })
    }
    function formatData(data) {
        const tempData = {
            "id": data.id,
        }
        return tempData;
    }

    const handleMoreTag = () => {
        navigate('/tags');
    };
function onClickHandler(){

}
    const pinUnpinSingleHandler = (e, data, noClear) => {
        e.stopPropagation();
        setDisablePinBtn(true);
        selectionCheckboxClear(data, noClear)
        // pinUnpinHandler(methodsAndState.dashboardList, methodsAndState.selectedItems, data.pinned ? "Unpin" : "Pin", methodsAndState.setSelectedItems, methodsAndState.setSnackBar, methodsAndState.setDashboardList);
    }
    const selectionCheckboxClear = (data, noClear) => {
        if (!noClear) {
            methodsAndState.checkboxGridHelper.clearSelection()
            methodsAndState.checkboxGridHelper.checkboxListener(data.id);
        }
        methodsAndState.selectedItems.clear()
        methodsAndState.selectedItems.add(data.id)
    }
    const buttonSX = {
        '&': { color: '#00000087' },
        '& .MuiButton-startIcon, & .MuiSvgIcon-root': { color: '#00000087' }
    }
    const desktopColumns = [
        { id: "", label: ' ', headerName: '', minWidth: 10, flex: 1 , 
        headerTemplate: (row) => {
            return (<>
                <Checkbox onClick={onClickHandler} checked={headerCheckStatus} />
            </>)
        },
        template: (key) => {
            return (<>
                <Checkbox
                    disabled={key.disable}
                    onClick={() => checkBoxHandler(key)}
                    checked={methodsAndState.selectedItems.has(key.id)}
                />
            </>)
        }
     },
        { id: "id", label: 'id', headerName: 'Type', minWidth: 10, flex: 1 , },
        { id: "name", field: 'Name', label: ' Name', minWidth:100, flex: 1 },
        { id: "description", field: 'Description', label: 'Description', minWidth: 200, flex: 1,  },
    ];
    const mobileColumns = [
        {
            id: 'select', label: '', width: 30, headerTemplate: () => {
                return (<>
                    <Checkbox onClick={methodsAndState.checkboxGridHelper.headerCheckboxListener} checked={headerCheckStatus} />
                </>)
            }, headerName: 'Type', flex: 1, template: (key) => {
                return (<>
                    <Box>
                        <Checkbox
                            disabled={key.disable}
                            onClick={() => checkBoxHandler(key)}
                            checked={methodsAndState.selectedItems.has(key.id)}
                        />

                    </Box>
                </>)
            }
        },
        {
            id: 'name', field: 'name', label: 'Name', width: 150, flex: 1, template: (row) => {
                return (<Box className={row.disable ? "disabled-container" : ""} sx={{ display: "flex", textWrap: "nowrap", cursor: "pointer", alignItems: "flex-start", justifyContent: "space-between", flexDirection: 'column' }}>
                    <Box sx={{ display: "flex", textWrap: "nowrap", cursor: "pointer", alignItems: "flex-start" }}>
                        {/* <ImageType data={row} /> */}
                        <HtmlTooltip title={row?.name} arrow>
                            <Box sx={{ alignSelf: 'center', padding: '0 5px', fontSize: '12px' }}>
                                {row?.name && row.name.length > 20 ? row.name.slice(0, 20) + '...' : row?.name}
                            </Box>
                        </HtmlTooltip>

                    </Box>

                    {/* Type */}
                    <Box sx={{ display: 'flex' }} >
                        <Box className="mobileViewBox" sx={{ marginRight: '5px' }}>
                            Owner:
                            <Typography variant='span' className='mobileViewStyle' >{row.owner}</Typography>
                        </Box>
                        <Box className="mobileViewBox" sx={{ marginRight: '5px' }}>
                            File Size:
                            <Typography variant='span' className='mobileViewStyle' >{row.fileSize}</Typography>
                        </Box>
                     
                    </Box>
                    <Box>
                        <Box className="mobileViewBox" sx={{ marginRight: '5px' }}>
                            Activity:
                            <Typography variant='span' className='mobileViewStyle' >
                                <Box>{row.activityName}</Box>
                            </Typography>
                            <Box><Typography variant="caption" sx={{ fontWeight: '400' }}>{row.date}</Typography></Box>
                        </Box>
                    </Box>
                </Box>)
            }
        },
        {
            id: 'tags',
            label: 'Tags',
            headerName: 'Tags',
            minWidth: 130,
            flex: 1,
            template: (row) => {
                const tags = row.tags || [];
                const visibleTags = tags.slice(0, 2);
                const remainingTags = tags.slice(2);

                return (
                    <Box onClick={handleMoreTag}>
                        {visibleTags.map((tag, index) => (
                            <React.Fragment key={tag.id} >
                                <Chip
                                    sx={{
                                        margin: '5px', borderRadius: '8px', cursor: 'pointer', '& .MuiChip-label': {
                                            fontSize: '12px',
                                        }
                                    }}
                                    label={tag.name.en}
                                />
                                {index < visibleTags.length - 1 && ' '}
                            </React.Fragment>
                        ))}
                        {remainingTags.length > 0 && (
                            <HtmlTooltip title={remainingTags.map((tag) => tag.name.en).join(', ')} arrow>
                                <span sx={{ mx: 2 }}>
                                    <Button >{`+${remainingTags.length}`}</Button>
                                </span>
                            </HtmlTooltip>
                        )}
                    </Box>
                );
            },
        }, {
            id: '3dots', label: '', headerName: '', minWidth: 130, flex: 1, template: (row) => {
                return (<Box onClick={() => selectionCheckboxClear(row)}>
                    {!row.disable ? <MenuBar data={checkedData} value={true} isRecentFiles={true} /> : null}
                </Box>)
            }
        },
    ];
    const fetchData = () => {
        // if (metaData?.last_page > metaData?.current_page) {
        //     dispatch(setScrollFlag(true))
        //     recentApiCall()
        // }
    }
    return (
        <Box sx={{ my: 3 }}>
            {(loading) ?
                (<>
                    <Skeleton variant="rounded" height={60} sx={{ my: '1rem', width: 1 }} />
                    <Skeleton variant="rounded" height={60} sx={{ my: '1rem', width: 1 }} />
                    <Skeleton variant="rounded" height={60} sx={{ my: '1rem', width: 1 }} />
                </>
                ) :
                (
                    <Box sx={{ position: 'relative' }}>
                        <InfiniteScroll
                            dataLength={methodsAndState.dashboardList.length}
                            next={fetchData}
                            hasMore={true} 
                            loader={(metaData?.last_page > metaData?.current_page) ? <Grid container spacing={2} >
                                {[1, 2, 3, 4].map((d, key) => {
                                    return (
                                        <Box key={key}>
                                            <Skeleton variant="rounded" sx={{ my: "1rem" }} width={1000} height={40} />
                                        </Box>
                                    );
                                })}
                            </Grid> : false}
                        >
                            <Box sx={{ width: '100%' }}>
                                <TableComponent data={methodsAndState.dashboardList} columns={isMobileView ? mobileColumns : desktopColumns}  />
                            </Box>
                        </InfiniteScroll>
                    </Box>
                )}
        </Box>
    )
}
export default PlaceholderCategoryTableView