import axios from 'axios';
import { getAppToken, getSelfToken } from "../_helper/secureToken"
// const BASE_URL = 'https://gatewayapi.eela.tech/api';
const BASE_URL = process.env.REACT_APP_BASEURL;
const MAIL_URL = process.env.REACT_APP_MAIL;


class APIService {
  constructor(baseURL, mailURL) {
    this.endpoints = {
      comapnyLog: "api/v1/company/1",
      mail: "api/mailtemplates",
      userInfo: "api/v1/userinfo",
      event: "api/events",
      eventCategory:"api/events",
      triggerMail:"api/trigger-mail"
    };
    this.api = axios.create({
      baseURL: baseURL
    });
    this.apiMail = axios.create({
      baseURL: mailURL
    });

  }
  async getRequest(apiInstance, endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      params.app = getAppToken();
      const response = await apiInstance.get(endpoint, {
        params,
        headers: { Authorization: 'Bearer ' + getSelfToken() },
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }
  
  async get(endpointKey, params = {}, searchParam = null) {
    return await this.getRequest(this.api, endpointKey, params, searchParam);
  }
  
  async getMail(endpointKey, params = {}, searchParam = null) {
    return await this.getRequest(this.apiMail, endpointKey, params, searchParam);
  }
  async view(endpointKey, id = null, params = {}, searchParam = null) {
    // const responseType = endpointKey === 'fileManager' ? 'blob' : 'json'; // Set the responseType based on endpointKey
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      params.app = getAppToken();
      const response = await axios.get(`https://mail.eela.tech/api/mailtemplates/${id}`, {
        params,
        headers: { Authorization: 'Bearer ' + getSelfToken() },
        // responseType
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', error);
    }


  }
  async post(endpointKey, data = null, searchParam = null) {
    try {
      //console.debug(data)
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      data.app = getAppToken();
      const response = await this.api.post(endpoint, data, {
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }
  async delete(endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      params.app = getAppToken();
      const response = await this.api.delete(endpoint, {
        params,
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }
  // async put(endpointKey, data = null, searchParam = null) {
  //   try {
  //     let endpoint = this.endpoints[endpointKey];
  //     if (searchParam) {
  //       endpoint = endpoint + '/' + searchParam;
  //     }
  //     data['app'] = getAppToken();
  //     const response = await this.api.put(endpoint, data, {
  //       headers: { Authorization: 'Bearer ' + getSelfToken() }
  //     });
  //     return response;
  //   } catch (error) {
  //     throw new Error('API request failed:', { error });
  //   }
  // }
  async put(endpointKey, data = null, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      data['app'] = getAppToken();
      const response = await this.api.put(endpoint, data, {
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }   
}



export default new APIService(BASE_URL,MAIL_URL);
