import React, { useEffect, useState } from 'react';
import EmailViewer from './../EmailViewer';
import ApiService from '../../../utils/ApiService';

const ViewMailTemplate = () => {
  const [emailTemplate, setEmailTemplate] = useState(null);
  const [emailTemplateCss, setEmailTemplateCss] = useState(null);

  
  useEffect(() => {
    const getEmailTemplate = async () => {
        try {
            ApiService.view('Hello',1).then(res => {
                let response = res.data
                if (response !=undefined) {
                  setTimeout(() => {
                    
                    setEmailTemplate(response);
                  }, 1000);
                    setEmailTemplateCss(response.css);

                }
            })
      
        } catch (error) {
          console.error('Error fetching email template:', error);
        }
      };

    getEmailTemplate();
  }, []);
console.debug("emailTemplate",emailTemplate)
  return (
    <div>
      {emailTemplate && <EmailViewer emailTemplate={emailTemplate} emailTemplateCss={emailTemplateCss}/>}
    </div>
  );
};

export default ViewMailTemplate;
